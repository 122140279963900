import Link from 'next/link'
import { useDispatch } from "react-redux";
import { clearLoginData } from "../redux/actions/userAction";
import { useSelector } from "react-redux";
import { userType } from "../components/global";
import { signOut } from 'next-auth/client';
import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Navbar(props) {
    const dispatch = useDispatch();
    const logindata = useSelector((state) => state.user);
    const router = useRouter();
    var islogged = logindata.islogged
    const handleLogout = async (e) => {
        e.preventDefault();
        await signOut();
        dispatch(clearLoginData());
    }

    const toggleAdmin = (e) => {
        e.preventDefault();
        var element = document.getElementById("myDIV");
        element.classList.toggle("show-dropdown");
    }

    const toggleMobile = (e, baseElementId, subItemClassName) => {
        e.preventDefault();
        var element = document.getElementById(baseElementId);
        element.classList.toggle("is-active");
        var elem = document.getElementsByClassName(subItemClassName);
        if (elem[0].style.display === 'block') {
            elem[0].style.display = 'none';
        } else {
            elem[0].style.display = 'block';
        }
    }

    let userID, user_Type, instituteId, isStripeConnectVerified, isInstitute = null;
    if (islogged) {
        userID = logindata.userdata._id;
        user_Type = logindata.userdata.userType;
        instituteId = logindata.userdata.institute?._id;
        isStripeConnectVerified = logindata.userdata.isStripeConnectVerified;
        isInstitute = logindata.userdata?.institute?._id ?? false;
    }

    let displayAccordingToUserType = user_Type == userType.instituteAdmin || (user_Type == userType.teacher && !isInstitute);
    let navigateUnVerifiedProfile = displayAccordingToUserType && !isStripeConnectVerified && !logindata.userdata.paypalUsername;

    const navigateTo = (path) => {
        if (!logindata.userdata?.isSubscribed && user_Type == userType.instituteAdmin)
            return `/`;
        else
            if (navigateUnVerifiedProfile)
                return `/`;
            else
                return path;
    }

    const onClickAlert = async (e, path) => {
        e.preventDefault();
        if (!logindata.userdata?.isSubscribed && user_Type == userType.instituteAdmin)
            return `/`;
        else
            if (navigateUnVerifiedProfile)
                if (confirm("You must need to verify your profile. Click OK to complete profile.") == true)
                    await router.push(`/userProfile/${logindata?.userdata?._id}`);
                else
                    e.preventDefault();
            else
                await router.push(path);
    }

    return (
        <>
            <Head>
                <link href="/vendor/css-hamburgers/hamburgers.min.css" rel="stylesheet" media="all" />
            </Head>

            {navigateUnVerifiedProfile && !props.isFromProfile ?
                <div className="alert alert-light text-center" role="alert">
                    <div>
                        Kindly Verify Your Profile &nbsp;&nbsp;
                        <Link href={`/userProfile/${logindata.userdata._id}`}>
                            <a className="btn btn-danger" style={{ padding: "0.1rem 0.75rem" }}>Verify</a>
                        </Link>
                    </div>
                </div> : null}

            <header className="header-desktop3 d-none d-lg-block">
                <div className="section__content section__content--p35">
                    <div className="header3-wrap">
                        <div className="header__logo">
                            <Link href={"/"}>
                                <a>Yam</a>
                            </Link>
                        </div>
                        <div className="header__navbar">
                            <ul className="list-unstyled">
                                <li>
                                    <Link href="/">
                                        <a>
                                            <i className="fas fa-tachometer-alt"></i>
                                            <span className="bot-line"></span>Dashboard
                                        </a>
                                    </Link>
                                </li>
                                <li className="has-sub">
                                    <a href={'#'} onClick={(e) => onClickAlert(e, `/students?usertype=${user_Type}&userId=${userID}&instituteId=${instituteId}`)}>
                                        <i className="fas fa-user"></i>Students
                                        <span className="bot-line"></span>
                                    </a>
                                </li>
                                {user_Type == userType.admin || user_Type == userType.instituteAdmin ?
                                    <li className="has-sub">
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/teachers?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
                                            <i className="fas fa-user"></i>Instructors
                                            <span className="bot-line"></span>
                                        </a>
                                    </li> : null}
                                {user_Type == userType.admin || user_Type == userType.instituteAdmin ?
                                    <li className="has-sub">
                                        <a href={user_Type == userType.admin ? "/institutes" : '#'} onClick={(e) => onClickAlert(e, `/institute/${instituteId}?userId=${userID}`)}>
                                            <i className="fas fa-university"></i>{user_Type == userType.admin ? "Institutes" : "Institute"}
                                            <span className="bot-line"></span>
                                        </a>
                                    </li> : null}

                                <li className="has-sub">
                                    <a href='#'>
                                        <i className="fas  fa-th-large"></i>e-Learning
                                        <span className="bot-line"></span>
                                    </a>
                                    <ul className="header3-sub-list list-unstyled">
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Courses</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/lessons?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Lessons</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/exams?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Assessments</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/questions?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Questions</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/categories`)}>Categories</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/tags`)}>Tags</a>
                                        </li>
                                    </ul>
                                </li>
                                {user_Type == userType.admin ?
                                    <li className="has-sub">
                                        <a href='#'>
                                            <i className="zmdi zmdi-balance-wallet"></i>Payments
                                            <span className="bot-line"></span>
                                        </a>
                                        <ul className="header3-sub-list list-unstyled">
                                            <li>
                                                <a href={'#'} onClick={(e) => onClickAlert(e, `/payments/courses`)}>Courses</a>
                                            </li>
                                            <li>
                                                <a href={'#'} onClick={(e) => onClickAlert(e, `/payments/subscriptions`)}>Subscriptions</a>
                                            </li>
                                        </ul>
                                    </li> : null}

                                <li className="has-sub">
                                    <a href={'#'} onClick={(e) => onClickAlert(e, `/chat`)}>
                                        <i className="fas fa-comments"></i>Chat
                                        <span className="bot-line"></span>
                                    </a>
                                </li>

                                <li className="has-sub">
                                    <a href='#'>
                                        <i className="fas fa-archive"></i>Archive
                                        <span className="bot-line"></span>
                                    </a>
                                    <ul className="header3-sub-list list-unstyled">
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Courses</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/lessons?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Lessons</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/exams?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Assessments</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/questions?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Questions</a>
                                        </li>

                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive//categories`)}>Categories</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/tags`)}>Tags</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="header__tool">
                            <div className="account-wrap">
                                <div id="myDIV" className="account-item account-item--style2 clearfix js-item-menu">
                                    <div className="content">
                                        <a className="js-acc-btn" onClick={(e) => toggleAdmin(e)}>{logindata.userdata?.firstName}</a>
                                    </div>
                                    <div className="account-dropdown js-dropdown">
                                        <div className="info clearfix">
                                            <div className="content">
                                                <h5 className="name">
                                                    {logindata.userdata?.lastName == null || undefined ? logindata.userdata?.firstName : logindata.userdata?.firstName + " " + logindata.userdata?.lastName} {user_Type == userType.admin ? "(Admin)" : user_Type == userType.instituteAdmin ? "(Institute Admin)" : null}
                                                </h5>
                                                <span className="email">{logindata.userdata?.email}</span>
                                            </div>
                                        </div>
                                        {user_Type != userType.admin ?
                                            <div className="account-dropdown__body">
                                                <div className="account-dropdown__item">
                                                    {!displayAccordingToUserType ?
                                                        <Link href={`/userProfile/ins/[id]`} as={`/userProfile/ins/${userID}`}>
                                                            <a><i className="zmdi zmdi-account"></i>Account</a>
                                                        </Link>
                                                        :
                                                        <Link href={`/userProfile/${userID}`}>
                                                            <a><i className="zmdi zmdi-account"></i>Account</a>
                                                        </Link>
                                                    }
                                                </div>
                                                {user_Type == userType.instituteAdmin ?
                                                    <div className="account-dropdown__item">
                                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/subscription`)}>
                                                            <i className="zmdi zmdi-notifications"></i>Subscriptions
                                                        </a>
                                                    </div> : null}
                                                {displayAccordingToUserType ?
                                                    <div className="account-dropdown__item">
                                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/payment/${logindata.userdata._id}?usertype=${user_Type}`)}><i className="zmdi zmdi-balance-wallet"></i>Payment</a>
                                                    </div> : null}
                                            </div> : null}

                                        <div className="account-dropdown__footer">
                                            <a onClick={(e) => handleLogout(e)}>
                                                <i className="zmdi zmdi-power"></i>Logout
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- END HEADER DESKTOP-->
            <!-- HEADER MOBILE--> */}
            <header className="header-mobile header-mobile-2 d-block d-lg-none">
                <div className="header-mobile__bar">
                    <div className="container-fluid">
                        <div className="header-mobile-inner">
                            <Link href="/">
                                <a>Yam</a>
                            </Link>
                            <button id="myDIV1" className="hamburger hamburger--slider" type="button" onClick={(e) => toggleMobile(e, 'myDIV1', 'navbar-mobile')}>
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <nav className="navbar-mobile">
                    <div className="container-fluid">
                        <ul className="navbar-mobile__list list-unstyled">
                            <li>
                                <Link href="/">
                                    <a>
                                        <i className="fas fa-tachometer-alt"></i>Dashboard
                                    </a>
                                </Link>

                            </li>
                            <li className="has-sub">
                                <a href={'#'} className="js-arrow" onClick={(e) => onClickAlert(e, `/students?usertype=${user_Type}&userId=${userID}&instituteId=${instituteId}`)}>
                                    <i className="fas fa-user"></i>Students
                                    <span className="bot-line"></span>
                                </a>
                            </li>

                            {user_Type == userType.admin || user_Type == userType.instituteAdmin ?
                                <li className="has-sub">
                                    <a href={'#'} onClick={(e) => onClickAlert(e, `/teachers?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>
                                        <i className="fas fa-users"></i>Instructors
                                        <span className="bot-line"></span>
                                    </a>
                                </li> : null}

                            {user_Type == userType.admin || user_Type == userType.instituteAdmin ?
                                <li className="has-sub">
                                    <a href={user_Type == userType.admin ? "/institutes" : '#'} onClick={(e) => onClickAlert(e, `/institute/${instituteId}?userId=${userID}`)}>
                                        <i className="fas fa-university"></i>{user_Type == userType.admin ? "Institutes" : "Institute"}
                                        <span className="bot-line"></span>
                                    </a>
                                </li> : null}
                            <li className="has-sub">
                                <a className="js-arrow" id="myDIV2" href='#' type="button" onClick={(e) => toggleMobile(e, 'myDIV2', 'navbar-mobile-sub__list')}>
                                    <i className="fas  fa-th-large"></i>e-Learning
                                    <span className="bot-line"></span>
                                </a>
                                <ul className="navbar-mobile-sub__list list-unstyled js-sub-list">
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Courses</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/lessons?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Lessons</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/exams?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Assessments</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/questions?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Questions</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, "/categories")}>Categories</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, "/tags")}>Tags</a>
                                    </li>
                                </ul>
                            </li>
                            {user_Type == userType.admin ?
                                <li className="has-sub">
                                    <a className="js-arrow" id="myDIV4" href='#' type="button" onClick={(e) => toggleMobile(e, 'myDIV4', 'navbar-mobile-sub__list 2')}>
                                        <i className="fas  fa-th-large"></i>Payments
                                        <span className="bot-line"></span>
                                    </a>
                                    <ul className="navbar-mobile-sub__list 2 list-unstyled js-sub-list">
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, "/payments/courses")}>Courses</a>
                                        </li>
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, "/payments/subscriptions")}>Subscriptions</a>
                                        </li>
                                    </ul>
                                </li> : null}
                            <li className="has-sub">
                                <a href={'#'} className="js-arrow" onClick={(e) => onClickAlert(e, "/chat")}>
                                    <i className="fas fa-comments"></i>Chat
                                    <span className="bot-line"></span>
                                </a>
                            </li>
                            <li className="has-sub">
                                <a className="js-arrow" id="myDIV3" href='#' type="button" onClick={(e) => toggleMobile(e, 'myDIV3', 'navbar-mobile-sub__list 1')}>
                                    <i className="fas fa-archive"></i>Archive
                                    <span className="bot-line"></span>
                                </a>
                                <ul className="navbar-mobile-sub__list 1 list-unstyled js-sub-list">
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/courses?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Courses</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/lessons?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Lessons</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/exams?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Assessments</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, `/archive/questions?usertype=${user_Type}&userID=${userID}&instituteId=${instituteId}`)}>Questions</a>
                                    </li>

                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, "/archive/categories")}>Categories</a>
                                    </li>
                                    <li>
                                        <a href={'#'} onClick={(e) => onClickAlert(e, "/archive/tags")}>Tags</a>
                                    </li>
                                </ul>
                            </li>

                            {user_Type != userType.admin ?
                                <>
                                    {!displayAccordingToUserType ?
                                        <li>
                                            <Link href={`/userProfile/ins/[id]`} as={`/userProfile/ins/${userID}`}>
                                                <a><i className="zmdi zmdi-account"></i>Account</a>
                                            </Link>
                                        </li>
                                        :
                                        <li>
                                            <Link href={`/userProfile/${userID}`}>
                                                <a><i className="zmdi zmdi-account"></i>Account</a>
                                            </Link>
                                        </li>
                                    }
                                    {user_Type == userType.instituteAdmin ?
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/subscription`)}>
                                                <i className="zmdi zmdi-notifications"></i>Subscriptions
                                            </a>
                                        </li> : null}
                                    {displayAccordingToUserType ?
                                        <li>
                                            <a href={'#'} onClick={(e) => onClickAlert(e, `/payment/${logindata.userdata._id}?usertype=${user_Type}`)}><i className="zmdi zmdi-balance-wallet"></i>Payment</a>
                                        </li> : null}
                                </> : null}
                            <li>
                                <a onClick={(e) => handleLogout(e)}>
                                    <i className="zmdi zmdi-power"></i>Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

        </>
    );
}


