import { SAVE_LOGIN, LOGIN_ERROR, CLEAR_LOGIN, UPDATE_LOGIN, UPDATE_PROFILE } from "../constants/actionTypes";
import { destroyCookie } from "nookies";
import { loginType } from "../../components/global"

export const clearLoginData = () => {
    return (dispatch) => {
        destroyCookie(null, 'token');
        dispatch({ type: CLEAR_LOGIN });
    }
}
export const updateSubscription = (data) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_LOGIN, data: data.xyz });
    }
}

export const updateUserProfile = (data) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_PROFILE, data: data.data });
    }
}

export const login_details = (userdata) => {
    return (dispatch) => {
        fetch(`/api/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userdata)
        })
            .then(res => res.json())
            .then(res2 => {
                if (res2.success) {
                    // let userResponse = res2.user
                    // let newObject = Object.assign({},userResponse,{subscription:res2.subscription});             
                    dispatch({ type: SAVE_LOGIN, data: res2.user, token: res2.token, subscription: res2.subscription });
                    document.cookie = `token=${res2.token};max-age=` + 60 * 60 * 24 * 365; //1yr                  

                    if (res2.user.loginType == loginType.email) {
                        let userArray = [];
                        let getUserArray = localStorage.getItem('userArray');
                        // 
                        if (getUserArray) {
                            userArray = JSON.parse(getUserArray)
                        }
                        let userLoginObj = {
                            'firstName': res2.user.firstName, 'lastName': res2.user.lastName, 'email':
                                res2.user.email, 'profileImage': res2.user.profileImage
                        }
                        userArray.push(userLoginObj)
                        let newUserArray = userArray.filter((x, index, self) =>
                            index === self.findIndex((t) => (
                                t.email === x.email
                            ))
                        )

                        localStorage.setItem('userArray', JSON.stringify(newUserArray));
                    }
                } else {
                    dispatch({ type: LOGIN_ERROR, data: res2.message });
                }
            })
    }
}
